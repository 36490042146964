.container {
    @apply
    flex items-center justify-center
    py-4
    w-full
    lg:w-60
    bg-main-yellow
    rounded-full
    text-main-dark
    text-xl
    font-semibold
    hover:opacity-80
    disabled:hover:opacity-100
    transition-all
}