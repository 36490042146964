.container {
    
    margin-top: 8% ;
    margin-bottom: 7% ;
    @apply
    flex justify-evenly w-full
    items-center 
    flex-col lg:flex-row
    mx-auto
    gap-20 lg:gap-0
    content-center
    h-full
    
    

}

.textSide {
    @apply
    w-96
    text-center lg:text-start
    text-7xl
    leading-big
    font-semibold
}

.deployButton {
    @apply
    mx-auto lg:mx-0
    text-lg mt-10
    disabled:bg-gray-100
    disabled:cursor-not-allowed
    cursor-pointer
}
