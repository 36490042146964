.box {
    @apply
    bg-blue-300
    w-80 lg:w-120
    h-80 lg:h-120
    mx-auto
    rounded-4xl
}

/* TRANSFORM AND LINEAR GRADIENT */

.outerFirst {
    background: linear-gradient(217.34deg, #D3BAC1 -2.61%, #6F6CE5 75.75%);
    transform: rotate(10deg);
}

.outerSecond {
    background: linear-gradient(217.34deg, #ECB261  0%,  #7A41EA 78.36%);
    transform: rotate(-10deg);
}

.outerThird {
    background: linear-gradient(217.34deg, #ECB261  0%,  #7A41EA 78.36%);
    transform: rotate(-10deg);
}
.outerFourth {
    background: linear-gradient(217.34deg, #C0449C 0%, #1B1DD4 78.36%);
    transform: rotate(10deg);
}
.outerFourth {
    @apply
    flex 
    flex-col
    items-center justify-center
}
