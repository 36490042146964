.ldsRing {
  @apply 
  inline-block
  relative
  w-8 h-8;
}

.ldsRing div {
  @apply 
  block
  absolute
  w-6 h-6
  m-1
  border-4 border-solid border-white
  rounded-full;
}

.ldsRing div {
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}